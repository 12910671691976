import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { store } from "../index";

import * as config from "../config";
import Logger from "./logger";

// const app = createApp({
//       apiKey: config.SHOPIFY_APP_API_KEY,
//       host: window.sessionStorage.getItem('host'),
//       // host: store.getState().data_states.shopifyHost,
//     });

function createShopifyApp() {
  Logger.log("createShopifyApp", store.getState().data_states.shopifyHost);
  return createApp({
    apiKey: config.SHOPIFY_APP_API_KEY,
    //host: localStorage.getItem("host"),
    host: store.getState().data_states.shopifyHost,
    forceRedirect: true,
  });
}

function getShopifySessionToken() {
  let _app = createShopifyApp();
  Logger.log("getShopifySessionToken APP", _app);
  return getSessionToken(createShopifyApp(_app));
}

export { createShopifyApp, getShopifySessionToken };
