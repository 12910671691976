import {
  Badge,
  Banner,
  Button,
  Card,
  ChoiceList,
  Heading,
  Layout,
  List,
  Toast,
  Page,
  Stack,
  TextField,
  Frame,
  ResourceList,
  Avatar,
  ResourceItem,
  TextStyle,
} from "@shopify/polaris";
import React from "react";
import CustomDatePicker from "../components/CustomDatepicker";
import { withRouter } from "react-router-dom";
import { Provider, ResourcePicker, TitleBar } from "@shopify/app-bridge-react";
import OfferxpApiClient from "../services/api/api-service";
import { getShopifySessionToken } from "../utils/auth-utils";
import campaign_default_logo from "../assets/images/campaign.png";
import * as config from "../config";
import { List as ListLoader } from "react-content-loader";
import UiUtils from "../utils/ui-utils";
import {publishEvent, EVENTS } from "../utils/analytics-utils";
import {store} from "../index";
import Logger from "../utils/logger";

const ProductPicker = (props) => {
  //const config = { apiKey: "12345", shopOrigin: "" };
  const cnfgn = {
    apiKey: config.SHOPIFY_APP_API_KEY,
    host: store.getState().data_states.shopifyHost,
  };
  return (
    <Provider config={cnfgn}>
      <ResourcePicker
        resourceType="Product"
        open={props.open}
        onSelection={props.onSelection}
        onCancel={props.onCancel}
      />
    </Provider>
  );
};

const MAX_COUPON_QTY = 10000;

const ProductList = (props) => {
  if (props.productList && props.productList.length > 0) {
    return (
      <Card>
        <ResourceList
          resourceName={{ singular: "product", plural: "products" }}
          items={props.productList}
          renderItem={(item) => {
            const { title, images } = item;
            const media = (
              <Avatar
                customer
                size="medium"
                name={title}
                source={
                  images.length > 0
                    ? images[0].originalSrc
                    : "https://via.placeholder.com/150/6d9e52/FFFFFF?Text=No image"
                }
              />
            );

            return (
              <ResourceItem
                media={media}
                accessibilityLabel={`View details for ${title}`}
              >
                <Stack>
                  <Stack.Item fill>
                    <h3>
                      <TextStyle variation="strong">{title}</TextStyle>
                    </h3>
                  </Stack.Item>
                  <Stack.Item>
                    <Button
                      plain
                      destructive
                      onClick={() => props.removeProduct(item)}
                    >
                      Remove
                    </Button>
                  </Stack.Item>
                </Stack>
              </ResourceItem>
            );
          }}
        />
      </Card>
    );
  } else {
    return null;
  }
};

class Campaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {
        store: null,
        created_at: null,
        updated_at: null,
        title: "",
        slug: "",
        coupon_code_length: "",
        products: null,
        coupon_prefix: "",
        coupon_postfix: "",
        coupon_code_count: null,
        applies_to: "Entire products",
        discount_value: null,
        discount_type: "Percentage",
        deleted: false,
        start_date: new Date(),
        end_date: new Date(new Date().setDate(new Date().getDate() + 1)),
        data: null,
      },
      createPage: false,
      isLoaded: false,
      openProductPicker: false,
      showToast: false,
      toastMsg: "",
      errorToast: false,
      isValidForm: true,
      isTitleValid: true,
      isDiscountValueValid: true,
      isCouponCodeCountValid: true,
      isCouponCodeLengthValid: true,
      isStartDateValid: true,
      isEndDateValid: true,
      isAppliesToValid: true,
      isLoadingCreateApi: false,
      isLoadingUpdateApi: false,
      isLoadingDeleteApi: false,
      datePickerError: null,

      // showProductPickerBtn: false,
    };
  }

  apiClient = new OfferxpApiClient();

  isFormValid = () => {
    let isValid = true;
    if (!this.state.campaign.title) {
      isValid = false;
      this.setState({
        isTitleValid: false,
      });
    }
    if (
      !this.state.campaign.discount_value ||
      !(this.state.campaign.discount_value > 0) ||
      (this.state.campaign.discount_type === "Percentage" &&
        this.state.campaign.discount_value > 100) ||
      this.state.campaign.discount_value > 1000000
    ) {
      isValid = false;
      this.setState({
        isDiscountValueValid: false,
      });
    }
    if (
      this.state.campaign.applies_to === "Selected products" &&
      (!this.state.campaign.products || this.state.campaign.products.length < 1)
    ) {
      isValid = false;
      this.setState({
        isAppliesToValid: false,
      });
    }
    if (
      !this.state.campaign.coupon_code_count ||
      !(this.state.campaign.coupon_code_count > 0) ||
      this.state.campaign.coupon_code_count > MAX_COUPON_QTY ||
      !Number.isInteger(this.state.campaign.coupon_code_count)
    ) {
      isValid = false;
      this.setState({
        isCouponCodeCountValid: false,
      });
    }
    if (
      !this.state.campaign.coupon_code_length ||
      !(this.state.campaign.coupon_code_length >= 6) ||
      this.state.campaign.coupon_code_length > 64 ||
      !Number.isInteger(this.state.campaign.coupon_code_length)
    ) {
      isValid = false;
      this.setState({
        isCouponCodeLengthValid: false,
      });
    }
    if (this.state.createPage) {
      // adding validation only for create page.
      if (
        this.state.campaign.start_date < new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate())
      ) {
        isValid = false;
        this.setState({
          isStartDateValid: false,
          datePickerError: "Start date cannot be in past",
        });
      }
      if (
        this.state.campaign.end_date <
        new Date(
          new Date().getFullYear(),
          new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate()).getMonth(),
          new Date().getDate()
        )
      ) {
        isValid = false;
        this.setState({
          isEndDateValid: false,
          datePickerError: "End date cannot be in past",
        });
      }
    }
    return isValid;
  };

  showCreateApiLoader(show) {
    if (show) {
      this.setState({
        //isLoaded: false,
        isLoadingCreateApi: true,
      });
    } else {
      this.setState({
        //isLoaded: true,
        isLoadingCreateApi: false,
      });
    }
  }
  showUpdateApiLoader(show) {
    if (show) {
      this.setState({
        //isLoaded: false,
        isLoadingUpdateApi: true,
      });
    } else {
      this.setState({
        //isLoaded: true,
        isLoadingUpdateApi: false,
      });
    }
  }
  showDeleteApiLoader(show) {
    if (show) {
      this.setState({
        //isLoaded: false,
        isLoadingDeleteApi: true,
      });
    } else {
      this.setState({
        //isLoaded: true,
        isLoadingDeleteApi: false,
      });
    }
  }

  componentDidMount() {
    if (this.props.match.params.slug === undefined) {
      let campaign = this.state.campaign;
      //campaign.store = localStorage.getItem("active-store");
      this.setState({
        createPage: true,
        campaign: campaign,
        isLoaded: true,
      });
    }
    if (this.props.match.params.slug) {
      this.apiClient
        .fetchCampaign(this.props.match.params.slug)
        .then(
          (response) => {
            // // response.data.campaign.start_date = new Date(
            // //   response.data.campaign.start_date
            // // );
            // // response.data.campaign.end_date = new Date(
            // //   response.data.campaign.end_date
            // // );
            // // response.data.campaigns[0].start_date = new Date();
            // // response.data.campaigns[0].end_date = new Date(
            // //   new Date().setDate(new Date().getDate() + 1)
            // // );
            // response.data.campaign.title = response.data.campaign.name;
            // // this.setState({
            // //   campaign: response.data.campaign,
            // //   isLoaded: true,
            // // });
            // Logger.log("campaign", response.data.campaign);
            // this.setState({
            //   campaign: response.data.campaign,
            //   isLoaded: true,
            // });
            response.data.campaign.start_date = new Date(
              response.data.campaign.start_date
            );
            response.data.campaign.end_date = new Date(
              response.data.campaign.end_date
            );
            this.setState({
              campaign: response.data.campaign,
              isLoaded: true,
            });
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });

            //UiUtils.showToast("error", `Request failed: ${error}`);
            this.showUnknownErrorToast();
          }
        )
        .catch((error) => {
          //UiUtils.showToast("error", `Request failed: ${error}`);
          this.showUnknownErrorToast();
        });
    }
  }

  showUnknownErrorToast = () => {
    this.showToast("Unexpected error occured!", true);
  };

  showToast = (msg, isError) => {
    this.setState({
      showToast: true,
      toastMsg: msg,
      errorToast: isError,
    });
  };
  dismissToast = () => {
    this.setState({
      showToast: false,
      toastMsg: "",
      errorToast: false,
    });
  };

  handleProductSelectionCancel = () => {
    this.setState({
      openProductPicker: false,
    });
  };

  handleSelectedProducts = (products) => {
    let campaign = this.state.campaign;
    campaign.products = products.selection;
    this.setState({
      openProductPicker: false,
      isAppliesToValid: true,
      isValidForm: true,
      campaign: campaign,
    });
  };

  removeProduct = (product) => {
    let campaign = this.state.campaign;
    campaign.products = this.removeObjectFromArray(campaign.products, product);
    this.setState({
      campaign: campaign,
    });
  };

  removeObjectFromArray(arr, { id }) {
    return arr.filter((obj) => obj.id !== id);
  }

  //--------Validation hints-----

  getDiscountValueValidationHint() {
    if (!this.state.campaign.discount_value) return "Please provide a value";
    if (!(this.state.campaign.discount_value > 0))
      return "Value must be greater than zero";
    if (
      this.state.campaign.discount_type === "Percentage" &&
      this.state.campaign.discount_value > 100
    )
      return "Percentage value should not exceed 100";
    if (this.state.campaign.discount_value > 1000000)
      return "The maximum allowable value is 1000000";
  }

  getCodeLengthValidationHint() {
    if (!this.state.campaign.coupon_code_length)
      return "Please provide a value";
    if (!(this.state.campaign.coupon_code_length >= 6))
      return "The minimum code length should be six";
    if (this.state.campaign.coupon_code_length > 64)
      return "The maximum allowable length is 64";
    if (!Number.isInteger(this.state.campaign.coupon_code_length))
      return "The length must be a whole number";
  }

  getCouponQuantityValidationHint() {
    if (!this.state.campaign.coupon_code_count) return "Please provide a value";
    if (!(this.state.campaign.coupon_code_count > 0))
      return "Value must be greater than zero";
    if (this.state.campaign.coupon_code_count > MAX_COUPON_QTY)
      return `The maximum allowable quantity is ${MAX_COUPON_QTY}`;
    if (!Number.isInteger(this.state.campaign.coupon_code_count))
      return "The quantity must be a whole number";
  }

  handleDiscountModeChange = (value) => {
    let campaign = this.state.campaign;
    campaign.applies_to = value[0];
    this.setState({
      campaign: campaign,
      isAppliesToValid: true,
    });
  };

  showProductPicker = () => {

    this.setState({
      openProductPicker: true,
    });
  };
  handleValueTypeChange = (value) => {
    let campaign = this.state.campaign;
    campaign.discount_type = value[0];
    this.setState({
      campaign: campaign,
    });
  };
  handleNameChange = (value) => {
    let campaign = this.state.campaign;
    campaign.title = value;
    this.setState({
      campaign: campaign,
      isValidForm: true,
      isTitleValid: true,
    });
  };
  handleCodeChange = (value) => {
    let campaign = this.state.campaign;
    campaign.slug = value;
    this.setState({
      campaign: campaign,
    });
  };
  handleDateRangeChange = (newDateRange) => {
    let campaign = this.state.campaign;
    let currentDate = new Date();
    let start_date = new Date(newDateRange.start);
    start_date.setHours(
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );

    let end_date = new Date(newDateRange.end);
    end_date.setHours(
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );

    campaign.start_date = start_date;
    campaign.end_date = end_date;
    this.setState({
      campaign: campaign,
    });
  };
  handleDiscountValueChange = (value) => {
    let campaign = this.state.campaign;
    campaign.discount_value = value;
    this.setState({
      campaign: campaign,
      isValidForm: true,
      isDiscountValueValid: true,
    });
  };

  handleCouponQuantityChange = (value) => {
    value = Number.parseInt(value);
    let campaign = this.state.campaign;
    campaign.coupon_code_count = value;
    this.setState({
      campaign: campaign,
      isValidForm: true,
      isCouponCodeCountValid: true,
    });
  };
  handleCodeLengthChange = (value) => {
    value = Number.parseInt(value);
    let campaign = this.state.campaign;
    campaign.coupon_code_length = value;
    this.setState({
      campaign: campaign,
      isValidForm: true,
      isCouponCodeLengthValid: true,
    });
  };

  deleteCampaign = () => {
    UiUtils.showConfirmDialog(
      "Are you sure?",
      "You won't be able to revert this!",
      "Yes, delete it!"
    ).then((result) => {
      if (result.isConfirmed) {
        this.showDeleteApiLoader(true);
        this.apiClient
          .deleteCampaign(this.state.campaign.slug)
          .then(
            (response) => {
							publishEvent(EVENTS.CODEGEN_CAMPAIGN_DELETED_SUCCESS, EVENTS.CODEGEN_CAMPAIGN_DELETED_SUCCESS);
              this.showToast("Campaign successfully removed!", false);
              this.showDeleteApiLoader(false);
              this.props.history.push("/campaigns/");
            },
            (error) => {
              if (error.response) {
								publishEvent(EVENTS.CODEGEN_CAMPAIGN_DELETED_FAILED, EVENTS.CODEGEN_CAMPAIGN_DELETED_FAILED);
                // UiUtils.showToast(
                //   "error",
                //   `Request failed: ${error?.response?.data}`
                // );
                this.showUnknownErrorToast();
                this.showDeleteApiLoader(false);
              }
            }
          )
          .catch((error) => {
            if (error.response) {
							publishEvent(EVENTS.CODEGEN_CAMPAIGN_DELETED_ERROR, EVENTS.CODEGEN_CAMPAIGN_DELETED_ERROR);
              // UiUtils.showToast(
              //   "error",
              //   `Request failed: ${error?.response?.data}`
              // );
              this.showUnknownErrorToast();
              this.showDeleteApiLoader(false);
            }
          });
      }
    });
  };

  handleCreateCampaign = () => {
    //validate form
    if (this.isFormValid()) {
      //create campaign
      this.showCreateApiLoader(true);
      this.apiClient
        .addCampaign(this.state.campaign)
        .then(
          (response) => {
						publishEvent(EVENTS.CODEGEN_CAMPAIGN_CREATED_SUCCESS, EVENTS.CODEGEN_CAMPAIGN_CREATED_SUCCESS)
            //UiUtils.showToast("success", "Campaign created");
            this.showCreateApiLoader(false);
            this.showToast("Campaign created", false);
            this.props.history.push("/campaigns/");
          },
          (error) => {
						publishEvent(EVENTS.CODEGEN_CAMPAIGN_CREATED_FAILED, EVENTS.CODEGEN_CAMPAIGN_CREATED_FAILED)
            this.showCreateApiLoader(false);
            if (error.response) {
              if (error.response) {
                // UiUtils.showToast(
                //   "error",
                //   `Request failed: ${error?.response?.data}`

                // );
                this.showUnknownErrorToast();
              }
            }
          }
        )
        .catch((error) => {
					publishEvent(EVENTS.CODEGEN_CAMPAIGN_CREATED_ERROR, EVENTS.CODEGEN_CAMPAIGN_CREATED_ERROR)
          if (error.response) {
            // UiUtils.showToast(
            //   "error",
            //   `Request failed: ${error?.response?.data}`
            // );

            this.showUnknownErrorToast();
            this.showCreateApiLoader(false);
          }
        });
    } else {
      this.showToast("Please fix the errors!", true);
      this.setState({
        isValidForm: false,
      });
    }
  };
  handleUpdateCampaign = () => {
    //validate form
    if (this.isFormValid()) {
      //update api
      this.showUpdateApiLoader(true);
      this.apiClient
        .editCampaign(this.state.campaign.slug, this.state.campaign)
        .then(
          (response) => {
						publishEvent(EVENTS.CODEGEN_CAMPAIGN_EDITED_SUCCESS, EVENTS.CODEGEN_CAMPAIGN_EDITED_SUCCESS)
            this.showUpdateApiLoader(false);
            //UiUtils.showToast("success", "Changes updated");
            this.showToast("Campaign updated", false);
          },
          (error) => {
						publishEvent(EVENTS.CODEGEN_CAMPAIGN_EDITED_FAILED, EVENTS.CODEGEN_CAMPAIGN_EDITED_FAILED)
            if (error.response) {
              // UiUtils.showToast(
              //   "error",
              //   `Request failed: ${error?.response?.data}`
              // );

              this.showUnknownErrorToast();
              this.showUpdateApiLoader(false);
            }
          }
        )
        .catch((error) => {
					publishEvent(EVENTS.CODEGEN_CAMPAIGN_EDITED_ERROR, EVENTS.CODEGEN_CAMPAIGN_EDITED_ERROR)
          if (error.response) {
            // UiUtils.showToast(
            //   "error",
            //   `Request failed: ${error?.response?.data}`
            // );
            this.showUnknownErrorToast();
            this.showUpdateApiLoader(false);
          }
        });
    } else {
      this.showToast("Please fix the errors!", true);
      this.setState({
        isValidForm: false,
      });
    }
  };

  handleCodes = () => {
    this.props.history.push(
      {pathname: `/campaigns/${this.state.campaign.slug}/coupon-codes`, state: {prevPath: this.props.location?.pathname}}
    );
  };

  exportCodes = () => {
    // this.apiClient.exportShopifyCoupons(this.state.campaign.slug, 'csv').then((res) => {
    // fileDownload(res.data, "coupons.csv");
    getShopifySessionToken().then((auth) => {
      // format=json is for DRF, so that DRF dont server HTML page, it will serve json if format=json is provided to URL params
      // auth=token <token>  --> this format is to satisfy backend criteria so that we don't have to make many changes on backend.
      window.open(
        `${config.BASE_SHOPIFY_PLUGIN_URL}campaigns/${this.state.campaign.slug}/export/?format=json&mode=json&auth=token ${auth}`,
        "_blank"
      );
    });
    // });
  };

  renderCampaignSummary = () => {
    return (
      <div>
        <Stack>
          <Stack.Item fill>
            <Page></Page>
          </Stack.Item>
          <Stack.Item>
            <Badge status="success">Active</Badge>
          </Stack.Item>
        </Stack>
        <Page>
          <h1 style={{ fontWeight: "bold" }}>{this.state.campaign.slug}</h1>
          <Heading>{this.state.campaign.title}</Heading>
          <Page></Page>
          <List type="bullet">
            {this.state.campaign.discount_value ? (
              <List.Item>
                {this.state.campaign.discount_value
                  ? this.state.campaign.discount_type === "Percentage"
                    ? this.state.campaign.discount_value + "% discount"
                    : "\u20B9" +
                      this.state.campaign.discount_value +
                      " flat discount"
                  : ""}
              </List.Item>
            ) : null}
            <List.Item>
              {this.state.campaign.applies_to === "Entire products"
                ? "Applicable to all products"
                : "Applicable to only specific products"}
            </List.Item>

            {this.state.campaign.coupon_code_count ? (
              <List.Item>
                {this.state.campaign.coupon_code_count + " Coupons"}
              </List.Item>
            ) : null}
          </List>
        </Page>
      </div>
    );
  };

  renderCampaignPage = () => {
    return (
      <Page>
        {!this.state.isValidForm ? (
          <Banner title={"Invalid Input!"} status={"critical"}>
            <p>Please fix the errors mentioned below and try again...</p>
          </Banner>
        ) : null}

        <Page>
          <Stack spacing="none">
            <img
              style={{ width: "40px", marginRight: "20px" }}
              src={campaign_default_logo}
              alt="activity-user"
            />
            <Heading>
              {this.state.campaign.slug
                ? this.state.campaign.title
                : "Create Campaign"}
            </Heading>
          </Stack>
        </Page>

        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <Stack vertical>
                  <TextField
                    label={"Campaign title"}
                    placeholder="2021 Summer Sales"
                    helpText={
                      "Enter a name that will make the campaign easier to identify"
                    }
                    onChange={this.handleNameChange}
                    value={this.state.campaign.title}
                    error={
                      !this.state.isTitleValid ? "Please provide a title" : ""
                    }
                  />
                  {this.state.campaign.slug ? (
                    <TextField
                      label={"Campaign code"}
                      helpText={
                        "Unique Identifier for Your Campaign (System Generated)"
                      }
                      onChange={this.handleCodeChange}
                      value={this.state.campaign.slug}
                      readOnly
                    />
                  ) : null}

                  <ChoiceList
                    title="Applies to"
                    choices={[
                      { label: "Entire products", value: "Entire products" },
                      {
                        label: "Selected products",
                        value: "Selected products",
                      },
                    ]}
                    selected={this.state.campaign.applies_to}
                    onChange={this.handleDiscountModeChange}
                    error={
                      !this.state.isAppliesToValid
                        ? "Please select at least one product"
                        : ""
                    }
                  />
                  {this.state.campaign.applies_to === "Selected products" ? (
                    <Stack vertical spacing="extraTight">
                      <small>Choose products that you offer discounts</small>
                      <Button onClick={this.showProductPicker}>
                        Select products
                      </Button>
                      <ProductList productList={this.state.campaign.products} removeProduct={this.removeProduct} />
                    </Stack>
                  ) : null}

                  <TextField
                    label={"Discount value"}
                    helpText={
                      "Enter the percentage or flat amount to generate the discount"
                    }
                    placeholder="30"
                    type={"number"}
                    max="100"
                    min="1"
                    onChange={this.handleDiscountValueChange}
                    value={
                      this.state.campaign.discount_value
                        ? this.state.campaign.discount_value.toString()
                        : ""
                    }
                    error={
                      !this.state.isDiscountValueValid
                        ? this.getDiscountValueValidationHint()
                        : ""
                    }
                  />
                  <ChoiceList
                    title="Discount type"
                    choices={[
                      { label: "Fixed values", value: "fixed_amount" },
                      { label: "Percentage", value: "Percentage" },
                    ]}
                    selected={this.state.campaign.discount_type}
                    onChange={this.handleValueTypeChange}
                  />
                  <TextField
                    label={"Code length"}
                    helpText={
                      "Enter the length of the discout codes to be generated"
                    }
                    type="number"
                    onChange={this.handleCodeLengthChange}
                    max="64"
                    min="6"
                    placeholder="6"
                    value={
                      this.state.campaign.coupon_code_length
                        ? this.state.campaign.coupon_code_length.toString()
                        : ""
                    }
                    error={
                      !this.state.isCouponCodeLengthValid
                        ? this.getCodeLengthValidationHint()
                        : ""
                    }
                  />
                  <TextField
                    label={"Coupon quantity"}
                    helpText={"Enter the quantity of coupons required"}
                    type="number"
                    onChange={this.handleCouponQuantityChange}
                    placeholder="50"
                    value={
                      this.state.campaign.coupon_code_count
                        ? this.state.campaign.coupon_code_count.toString()
                        : ""
                    }
                    error={
                      !this.state.isCouponCodeCountValid
                        ? this.getCouponQuantityValidationHint()
                        : ""
                    }
                  />
                  <p>Chose start date and end date</p>
                  <Stack>
                    <Page>
                      <CustomDatePicker
                        onDateChange={this.handleDateRangeChange}
                        dateRange={{
                          start: this.state.campaign.start_date,
                          end: this.state.campaign.end_date,
                        }}
                        errorMessage={this.state.datePickerError}
                      />
                    </Page>
                    {/* <Page vertical={true}>
                <p>End date</p>
                <CustomDatePicker></CustomDatePicker>
              </Page> */}
                  </Stack>
                </Stack>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Summary" sectioned>
              {this.state.campaign.title || this.state.campaign.slug ? (
                this.renderCampaignSummary()
              ) : (
                <p>No data available...</p>
              )}
            </Card>
          </Layout.Section>
        </Layout>
        <Page>
          <Stack>
            <Stack.Item fill>
              <Stack>
                <Button
                  primary
                  loading={
                    this.state.isLoadingCreateApi ||
                    this.state.isLoadingUpdateApi
                  }
                  onClick={
                    this.state.createPage
                      ? this.handleCreateCampaign
                      : this.handleUpdateCampaign
                  }
                >
                  {this.state.createPage ? "Create" : "Update"}
                </Button>
                {!this.state.createPage ? (
                  <Button
                    destructive
                    onClick={this.deleteCampaign}
                    loading={this.state.isLoadingDeleteApi}
                  >
                    Delete
                  </Button>
                ) : null}

                {!this.state.createPage ? (
                  <Button primary onClick={this.handleCodes}>
                    Codes
                  </Button>
                ) : null}
                {this.state.campaign.slug ? (
                  <Button primary onClick={this.exportCodes}>
                    Export
                  </Button>
                ) : null}
              </Stack>
            </Stack.Item>
            <Stack.Item>
            </Stack.Item>
          </Stack>
        </Page>
        <ProductPicker
          open={this.state.openProductPicker}
          onSelection={this.handleSelectedProducts}
          onCancel={this.handleProductSelectionCancel}
        />
        {this.state.showToast ? (
          <Frame>
            <Toast
              content={this.state.toastMsg}
              error={this.state.errorToast}
              onDismiss={this.dismissToast}
              duration={1000}
            />
          </Frame>
        ) : null}
      </Page>
    );
  };

  renderLoader = () => {
    return (
      <Page>
        <ListLoader />
      </Page>
    );
  };

  render() {
    let Jsx;
    if (!this.state.isLoaded) {
      Jsx = this.renderLoader();
    } else {
      Jsx = this.renderCampaignPage();
    }
   return(
    <Provider config={{ apiKey: config.SHOPIFY_APP_API_KEY, host: store.getState().data_states.shopifyHost}}>
    <TitleBar primaryAction={{
      content: '←  Go Back',
      onAction: () => this.props.history.push({pathname: "/campaigns", state: {campaignList: undefined}}),
      // onAction: () => this.props.history.goBack(),
    }}/>
    {Jsx}
  </Provider>
   )
  }
}
export default withRouter(Campaign);
