import React from "react";

import OfferxpApiClient from "../services/api/api-service";
import { connect } from "react-redux";
import * as actionTypes from "../store/actions";
import * as config from "../config";
import Logger from "../utils/logger";
import { createShopifyApp } from "../utils/auth-utils";
import CookieService from "../services/cookie-service";
import { Redirect, withRouter } from "react-router-dom";
import createApp from "@shopify/app-bridge";
import {
  History,
  Redirect as ShopifyRedirect,
} from "@shopify/app-bridge/actions";
import { getSessionToken } from "@shopify/app-bridge-utils";

class shopifyAuthCallback extends React.Component {
  componentDidMount() {
    const apiClient = new OfferxpApiClient();
    let urlParams = new URLSearchParams(
      this.props.location.search.split("?")[1]
    );
    let code = urlParams.get("code");
    let host = urlParams.get("host");
    const app = createApp({
      apiKey: config.SHOPIFY_APP_API_KEY,
      host: host,
      forceRedirect: false,
    });
    Logger.log("APP IS", app);
    this.props.setShopifyHost(host);
    this.props.setShopifyAppBridgeInstance(app);
    const history = History.create(app);

    if (code) {
      let params = {
        embedded: urlParams.get("embedded"),
        code: code,
        hmac: urlParams.get("hmac"),
        timestamp: urlParams.get("timestamp"),
        shop: urlParams.get("shop"),
        state: urlParams.get("state"),
        host: host,
      };
      apiClient.saveStoreConnection(params).then((response) => {
        Logger.log("response.data", response.data, response.data.connected);
        // if (window === window.parent) {
        // this app should run in embedded mode, redirecting to shopify if used as standalone
        console.log("REDIRECT TO HOME PAGE AFTER INSTALLATION");
        // let pathToRedirect = `/?${urlParams}`;
        let newUrl = `http://${urlParams.get("shop")}/admin/apps/${
          process.env.REACT_APP_SHOPIFY_APP_API_KEY
        }?host=${urlParams.get("host")}`;
        Logger.log(
          "window === window.parent",
          window === window.parent,
          newUrl,
          process.env.REACT_APP_SHOPIFY_APP_API_KEY
        );

        history.dispatch(History.Action.PUSH, "/home");
        // redirect.dispatch(ShopifyRedirect.Action.APP, "/home");
        this.props.history?.push({ pathname: "/home" });
        // window.location.replace(newUrl);
        // window.location.replace(pathToRedirect);
        // }
      });
    } else {
      Logger.log(
        "Check",
        code,
        urlParams.get("host"),
        urlParams.get("hmac"),
        urlParams.get("shop"),
        urlParams.get("timestamp")
      );
      if (
        urlParams.get("host") &&
        urlParams.get("hmac") &&
        urlParams.get("shop") &&
        urlParams.get("timestamp")
      ) {
        let installationUrl = null;
        let isConnected = null;
        // this.props.setShopifyHost(host);

        Logger.log("authCallback CDM with host", host, app);
        // const app = createShopifyApp();
        // this.props.setShopifyAppBridgeInstance(app);
        // let redirectUrl = encodeURIComponent(document.location.hostname + '/auth/callback/')
        // let permissionUrl =  `${urlParams.get('shop')}/admin/auth/authorize?client_id=${config.SHOPIFY_APP_API_KEY}&scope=write_price_rules&redirect_uri=${encodeURIComponent(redirectUrl)}&state=`
        // console.log('permissionUrl', permissionUrl)
        // window.location.replace(permissionUrl);

        apiClient
          .initiateStoreConnection(urlParams.get("shop"))
          .then((response) => {
            installationUrl = response.data.auth_url;
            isConnected = response.data.is_connected;

            console.log(
              "REDIRECT 2",
              this.props,
              installationUrl,
              isConnected,
              response
            );

            if (isConnected) {
              getSessionToken(app)
                .then((sessionToken) => {
                  Logger.log("sessionToken is:", host, sessionToken);
                  this.props.setShopifyHost(host);
                  this.props.setShopifyAppBridgeInstance(app);

                  history.dispatch(History.Action.PUSH, "/home");
                  // redirect.dispatch(ShopifyRedirect.Action.APP, "/home");
                  this.props.history?.push({ pathname: "/home" });
                })
                .catch((error) => {
                  Logger.log("authCallback getSessionToken", error);
                  // redirect.dispatch(ShopifyRedirect.Action.REMOTE, installationUrl);
                });
            } else {
              Logger.log("installationUrl", installationUrl);
              if (installationUrl) {
                // redirect.dispatch(
                //   ShopifyRedirect.Action.REMOTE,
                //   installationUrl
                // );
                // window.location.replace(response.data.auth_url);
                // try histroy.replace
                window.location.href = installationUrl;
              }
            }

            // window.location.replace(response.data.auth_url);
            // redirect.dispatch(
            //   ShopifyRedirect.Action.REMOTE,
            //   response.data.auth_url
            // );

            // getSessionToken(app)
            //   .then((sessionToken) => {
            this.props.setShopifyHost(host);

            // redirect.dispatch(ShopifyRedirect.Action.ADMIN_PATH, "/home");
          })
          .catch((error) => {
            Logger.log("Axios error", error);
          });
      }
    }
  }

  render() {
    let urlParams = new URLSearchParams(
      this.props.location.search.split("?")[1]
    );
    console.log(
      "authCallback render",
      urlParams.get("code"),
      urlParams.get("host")
    );
    if (urlParams.get("host") && urlParams.get("host").length > 0) {
      let host = urlParams.get("host");
      this.props.setShopifyHost(host);
    }

    // if (urlParams.get("host")) {
    //   Logger.log("Render shopifyConfig", host);
    //   this.props.setShopifyHost(host);
    //   const app = createShopifyApp();
    //   this.props.setShopifyAppBridgeInstance(app);
    //   ShopifyRedirect.create(app).dispatch(ShopifyRedirect.Action.APP, "/home");
    //   // ShopifyRedirect.create(app).dispatch(
    //   //   ShopifyRedirect.Action.ADMIN_PATH,
    //   //   "/customers"
    //   // );
    //   // return <Redirect to={pathToRedirect} />;
    // } else {
    //   Logger.log("authCallback render host missing");
    // }
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    shopifyHost: state.data_states.shopifyHost,
    shopifyAppBridgeInstance: state.data_states.shopifyAppBridgeInstance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShopifyHost: (host) => {
      dispatch({
        type: actionTypes.SET_SHOPIFY_HOST,
        payload: host,
      });
    },
    setShopifyAppBridgeInstance: (app) =>
      dispatch({
        type: actionTypes.SET_SHOPIFY_APP,
        payload: app,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(shopifyAuthCallback));
