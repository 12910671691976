import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useAppBridge, useRoutePropagation } from "@shopify/app-bridge-react";
import routes from "./Routes";
// import routes from "./_routes";
import Logger from "./utils/logger";
// import "@shopify/polaris/build/esm/styles.css";
require("dotenv").config();

function App(props) {
  const app = useAppBridge();
  Logger.log("App.js", app, props);
  // useRoutePropagation(props);
  const routers = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });
  return (
    <BrowserRouter basename="/">
      <Switch>{routers}</Switch>
      {/* <Switch>{routes}</Switch> */}
    </BrowserRouter>
  );
}

export default App;
