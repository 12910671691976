import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
// import { Provider } from "react-redux";
import reducer from "./store/reducers";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { BrowserRouter, Link } from "react-router-dom";
import { Provider as AppBridgeProvider } from "@shopify/app-bridge-react";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider as PolarisProvider } from "@shopify/polaris";
import * as projectConfig from "./config";
import AppRouter from "./AppRouter";
import { Provider as ReduxProvider } from "react-redux";
import Logger from "./utils/logger";
import "@shopify/polaris/build/esm/styles.css";

const initialState = {};
const middleware = [thunk];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
});

export const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

const CustomLinkComponent = ({ children, url, ...rest }) => {
  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
};

function _App(props) {
  const host = new URL(window.location).searchParams.get("host");
  // const host = Buffer.from(`${shop}/admin`).toString('base64');
  const shopifyConfig = {
    apiKey: projectConfig.SHOPIFY_APP_API_KEY,
    host: host,
    forceRedirect: true,
  };
  Logger.log("Index page", shopifyConfig, props);
  return (
    <ReduxProvider store={store}>
      <AppBridgeProvider config={shopifyConfig}>
        <PolarisProvider i18n={enTranslations}>
          <App />
        </PolarisProvider>
      </AppBridgeProvider>
    </ReduxProvider>
  );
}
ReactDOM.render(<_App />, document.getElementById("root"));

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <AppBridgeProvider>
//         <ReduxProvider store={store}>
//           <PolarisProvider
//             i18n={enTranslations}
//             linkComponent={CustomLinkComponent}
//           >
//             <AppRouter />
//             <App />
//           </PolarisProvider>
//         </ReduxProvider>
//       </AppBridgeProvider>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
