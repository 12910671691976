import { Page, Layout, EmptyState } from "@shopify/polaris";
import React from "react";
import { useHistory, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import OfferxpApiClient from "../services/api/api-service";
import UiUtils from "../utils/ui-utils";

import { List } from "@shopify/polaris";
import Logger from "../utils/logger";
import {
  SessionToken,
  Redirect as ShopifyRedirect,
} from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
import { useState } from "react";
import { useEffect } from "react";

//const img = 'https://cdn.shopify.com/s/files/1/0593/7784/4411/files/reward_default_e81c57ec-cce1-4b9f-aedc-b86c9ddd775c.png?v=1629142638';

const Welcome = () => {
  const history = useHistory();
  const handleAction = () => {
    history.push("/campaigns/create");
  };
  return (
    <Page>
      <Layout>
        <EmptyState // Empty state component
          heading="Create your first campaign!"
          action={{
            content: "Create campaign",
            onAction: handleAction,
          }}
          //image={img}
        >
          <p>You can generate unique discout codes from the campaign</p>
        </EmptyState>
      </Layout>
    </Page>
  );
};

function Home(props) {
  const [campaignList, setCampaignList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const app = useAppBridge();
  Logger.log("App.js", app);
  let content = null;

  useEffect(() => {
    const apiClient = new OfferxpApiClient();
    apiClient
      .fetchCampaigns()
      .then(
        (response) => {
          Logger.log("useEffect api called");
          setCampaignList(response.data.campaigns);
          setIsLoaded(true);
          if (response.data.campaigns && response.data.campaigns?.length > 0) {
            props.history.push({
              pathname: "/campaigns",
              state: { campaignList: response.data.campaigns },
            });
          }
        },
        (error) => {
          Logger.log("error", error);
          if (error.response) {
            UiUtils.showToast(
              "error",
              `Request failed: ${error?.response?.data}`
            );
          }
        }
      )
      .catch((error) => {
        if (error.response) {
          Logger.log("error1", error);
          UiUtils.showToast(
            "error",
            `Request failed: ${error?.response?.data}`
          );
        }
      });
  }, []);
  if (campaignList && campaignList?.length === 0) {
    content = <Welcome />;
  }
  console.log("HOME render", campaignList, isLoaded, content);
  return content;
  // <List type="bullet">
  //   <List.Item>Yellow shirt</List.Item>
  //   <List.Item>Red shirt</List.Item>
  //   <List.Item>Green shirt</List.Item>
  // </List>
}

const mapStateToProps = (state) => {
  return {
    shopifyHost: state.data_states.shopifyHost,
  };
};

export default connect(mapStateToProps)(withRouter(Home));
