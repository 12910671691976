import React from "react";
import {
  AccountConnection,
  Layout,
  Link,
  Page,
  Card,
  Form,
  FormLayout,
  Stack,
  TextField,
  Button,
  TextStyle,
  SettingToggle,
} from "@shopify/polaris";

import OfferxpApiClient from '../services/api/api-service';
import Logger from "../utils/logger";

export default class StoreConnection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: props.connected,
      storeName: props.storeName,
      storeAddress: props.storeAddress,
      offersEnabled: props.offersEnabled,
      ownerName: props.ownerName,
      ownerEmail: props.ownerEmail,
      ownerInitials: props.ownerInitials,
      remount: false,
    };
    this.toggleConnection = this.toggleConnection.bind(this);
    this.apiClient = new OfferxpApiClient();
  }

  // componentDidMount(){
  //   this.setState({
  //     mounted: true
  //   })
  // }

  static getDerivedStateFromProps(props, state) {
    if (state.remount === true) {
      state["remount"] = false;
      return state;
    }
    return props;
  }

  toggleConnection() {
    if (this.state.connected) {
      //  if store is connected then disconnect
      this.apiClient.disconnectStore();
    }
    this.setState({ connected: !this.state.connected, remount: true });
  }

  handleSubmit = () => {
    this.setState({
      storeAddress: this.state.storeAddress,
    });

    this.apiClient
      .initiateStoreConnection(this.state.storeAddress)
      .then((response) => (window.location = response.data.auth_url))
      .catch((error) => {
        Logger.log(error.message);
      });
  };

  handleChange = (fieldName, value) => {
    {
      this.setState({ [fieldName]: value, remount: true });
    }
  };

  handleOffersEnabledChange = (event) => {
    let offersEnabled = event.target.textContent === "Disable" ? true : false;
    Logger.log("offersEnabled", offersEnabled);
    //  if  text is disable, then offers are enabled, else disabled.
    this.apiClient.updateOffersEnabled(!offersEnabled).then((response) => {
      this.setState({
        offersEnabled: response.data.offers_enabled,
        remount: true,
      });
    });
  };

  renderOfferEnabledMarkup() {
    const contentStatus =
      this.state.offersEnabled === true ? "Disable" : "Enable";
    const textStatus = this.state.offersEnabled ? "enabled" : "disabled";
    return (
      <SettingToggle
        action={{
          content: contentStatus,
          onAction: this.handleOffersEnabledChange,
        }}
        enabled={this.state.offersEnabled}
      >
        Enable Rewards
        {/* <TextStyle variation="strong">{textStatus}</TextStyle>. */}
      </SettingToggle>
    );
  }

  renderConnectedStoreMarkup() {
    return (
      <Page>
        <AccountConnection
          avatarUrl="https://gravatar.com/avatar/57dde0bd2de4350c196d9fb235703b83?s=200"
          accountName={this.state.ownerName}
          details={this.state.storeAddress}
          action={{ content: "Disconnect", onAction: this.toggleConnection }}
          connected={this.state.connected}
        />
        {this.renderOfferEnabledMarkup()}
      </Page>
    );
  }

  renderDisconnectedStoreMarkup() {
    return (
      <Page>
        <Layout>
          <Layout.AnnotatedSection
            title="Store Address"
            description="Enter your shopify store URL that your would like to connect with OfferXP."
          >
            <Card sectioned>
              <Form onSubmit={this.handleSubmit}>
                <FormLayout>
                  <TextField
                    value={this.state.storeAddress}
                    onChange={(value) =>
                      this.handleChange("storeAddress", value)
                    }
                    value={this.state.storeAddress}
                    label="Store Address"
                    type="storeAddress"
                  />
                  <Stack distribution="trailing">
                    <Button primary submit>
                      Connect Store
                    </Button>
                  </Stack>
                </FormLayout>
              </Form>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    );
  }

  _renderDisconnectedStoreMarkup() {
    return (
      <div className="store_connection_wrapper">
        {/* <Layout>
        <Input
        title="Store Name"
        htmlID="store_name"
        name="store_name"
        type="text"
        value={this.state.storeAddress}
        placeholder="Enter Store Name"
        showLabel={true}
        // labelFor={this.state.storeAddress}
        // handleChange={this.handleUserId}
      />
      <AccountConnection
      // title="Dropshipp"
      action={{content: 'Connect', onAction: this.toggleConnection.bind(this, this.state)}}
      // details="No account connected"
      // termsOfService={<p>By clicking Connect, you agree to accept Dropshipp’s <Link url="https://shopify.com">Terms and conditions</Link>. You’ll pay a commission rate of 15% on sales made through Dropshipp.</p>}
      connected={this.state.connected}
    />
    </Layout> */}
      </div>
    );
  }

  renderMarkup() {
    return this.state.connected
      ? this.renderConnectedStoreMarkup()
      : this.renderDisconnectedStoreMarkup();
  }

  render() {
    return this.renderMarkup();
  }
}
