import React from "react";
// import {
// 	Col,
// 	Card,
// 	Table,
// 	ListGroup,
// 	Button,
// 	OverlayTrigger,
// 	Tooltip
// } from "react-bootstrap";

// import Aux from "../../hoc/_Aux";
// import { BulletList } from 'react-content-loader'
// import OfferxpApiClient from "../../services/api-services";
// import campaign_ic from "../../assets/images/widget/campaign.png";
// import DEMO from "../../store/constant";
// import { withRouter } from "react-router-dom";
// import * as actionTypes from "../../store/actions";
// import { connect } from "react-redux";
// import UiUtils from "../../oxp_utils/ui-utils";

class CouponCode extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       campaignList: [],
//     };
//     this.isLoaded = false;
//     this.apiClient = new OfferxpApiClient();
//   }

//   launchCampaignPage = (campaign) => {
//     let path = `/campaigns/${campaign.id}/`;
//     //let path = '/activity';
//     let history = this.props.history;
//     this.props.setSelectedCampaign(campaign.id);
//     history.push(path);
//   };

//   componentDidMount() {
//     this.apiClient
//       .fetchCampaigns()
//       .then(
// 	      (response) => {
// 		      this.isLoaded = true;
// 		      this.setState({
// 			      campaignList: response.data.campaigns,
// 			      //campaignList: MockResponse.CAMPAIGN_LIST
// 		      });
// 	      },
// 	      (error) => {
// 		      this.isLoaded = false;
// 		      // this.setState({
// 		      //   //campaignList: response.data.campaigns
// 		      //   campaignList: MockResponse.CAMPAIGN_LIST,

// 		      // });
// 		      UiUtils.showToast("error", `Request failed: ${error}`);
// 	      }
//       )
//       .catch((error) => {
//         // this.isLoaded = true;
//         // this.setState({
//         //   //campaignList: response.data.campaigns
//         //   campaignList: MockResponse.CAMPAIGN_LIST,
//         UiUtils.showToast("error", `Request failed: ${error}`);
//       });
//   }

//   render() {
//     return (
//       <Aux>
//         <div className="dashboard-activity-main">
//           <ListGroup>
// 	          <Col md={12}>
// 		          <Card className="Recent-Users">
// 			          <Card.Header>
// 				          <Card.Title as="h5">Campaigns</Card.Title>
// 			          </Card.Header>
// 			          <Card.Body className="px-0 py-2">
// 				          {this.isLoaded && (
// 					          <Table responsive hover>
// 						          <tbody>
// 						          {this.state.campaignList.map((campaign, i) => {
// 							          return (
//                             <tr
//                               style={{ cursor: "pointer" }}
//                               className="unread"
//                               key={i}
//                               onClick={() => this.launchCampaignPage(campaign)}
//                             >
// 	                            <td>
// 		                            <img
// 			                            className="rounded-circle"
// 			                            style={{width: "40px"}}
// 			                            src={campaign_ic}
// 			                            alt="activity-user"
// 		                            />
// 	                            </td>
// 	                            <td>
// 		                            <h6 className="mb-1">{campaign.name}</h6>
// 		                            <p className="m-0 campaign_dates">
// 			                            <OverlayTrigger
// 				                            placement="bottom"
// 				                            overlay={<Tooltip id="button-tooltip-2" style={{fontSize: '10px', padding: '5px'}}>Start
// 					                            Date</Tooltip>}
// 			                            >
// 				                            <i className='fa fa-calendar-o m-r-15'> {campaign.start_date.substring(0, 10)}</i>
// 			                            </OverlayTrigger>
// 			                            <OverlayTrigger
// 				                            placement="bottom"
// 				                            overlay={<Tooltip id="button-tooltip-2" style={{fontSize: '8px', padding: '5px'}}>End
// 					                            Date</Tooltip>}
// 			                            >
// 				                            <i className='fa fa-calendar-check-o'> {campaign.end_date.substring(0, 10)}</i>
// 			                            </OverlayTrigger>
// 		                            </p>
// 	                            </td>
// 	                            <td>
// 		                            <h6 className="text-muted">
// 			                            <i
// 				                            className={
// 					                            campaign.is_active
// 						                            ? "fa fa-circle text-c-green f-10 m-r-15"
// 						                            : "fa fa-circle text-c-red f-10 m-r-15"
// 				                            }
//                                   />
//                                   {campaign.is_active ? "Active" : "Inactive"}
//                                 </h6>
//                               </td>
//                             </tr>
//                           );
//                         })}
//                       </tbody>
//                     </Table>
//                   )}
//                   {!this.isLoaded && (
//                     <Table responsive hover>
//                       <tbody>
//                         <tr className="unread">
//                           <td>
//                             <h6
//                               className="mb-1"
//                               style={{
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                               }}
//                             >
//                              <BulletList/>
//                             </h6>
//                             <p className="m-0">&nbsp;</p>
//                           </td>
//                         </tr>
//                       </tbody>
//                     </Table>
//                   )}
//                 </Card.Body>
//               </Card>
//             </Col>
//           </ListGroup>
// 	        <Button
// 		        className="m-l-15"
// 		        variant="primary"
// 		        onClick={() => this.props.history.push("/campaigns/create")}
// 	        >
// 		        + Add Campaign
// 	        </Button>
//         </div>
//       </Aux>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     selectedCampaign: state.selectedCampaign,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setSelectedCampaign: (campaign) =>
//       dispatch({
//         type: actionTypes.UPDATE_SELECTED_CAMPAIGN,
//         payload: campaign,
//       }),
//   };
// };

// //export default withRouter(CampaignList);
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(CampaignList));

}
export default CouponCode;
