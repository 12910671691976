export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
//  export const BASE_URL = 'https://bdev.offerxp.com/';
// export const BASE_URL = 'https://0523-103-47-14-210.ngrok.io/';
export const BASE_SHOPIFY_URL = BASE_URL + "shopify/";
export const SHOPIFY_APP_API_KEY = process.env.REACT_APP_SHOPIFY_APP_API_KEY;
// export const SHOPIFY_APP_API_KEY =  '4499f8e9b0c552ceec63bfd523709632';
export const SHOPIFY_APP_URL = process.env.REACT_APP_SHOPIFY_APP_SLUG; // app slug on shopify
export const BASE_SHOPIFY_PLUGIN_URL = BASE_URL + "codegen/";
export const KEY_TOKEN = "token";
export const KEY_SHOPIFY_TOKEN = "shopify-token";
export const SHOPIFY_DATA = {
  host: "",
};
