import { DatePicker, InlineError } from "@shopify/polaris";
import { useState, useCallback, useEffect } from "react";
import Logger from "../utils/logger";

export default function CustomDatePicker(props) {
  const [{ month, year }, setDate] = useState({
    // month: new Date().getMonth(),
    // year: new Date().getYear() + 1900,
    month: props.dateRange.start.getMonth(),
    year: props.dateRange.start.getYear() + 1900,
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(new Date().setDate(new Date().getDate() + 1)),
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const onDateChange = (newDateRange) => {
    //setSelectedDates(newDateRange);
    if (props.onDateChange) {
      props.onDateChange(newDateRange);
    }
  };

  useEffect(() => {
    if (props.dateRange) {
      Logger.log("dates", ":useEffect", props.dateRange);
      setSelectedDates(props.dateRange);
    }
  }, [props.dateRange]);

  return (
    <div>
      <DatePicker
        month={month}
        year={year}
        onChange={onDateChange}
        onMonthChange={handleMonthChange}
        selected={selectedDates}
        allowRange
      />
      <InlineError message={props.errorMessage} fieldID="datePickerError" />
    </div>
  );
}
