const getProductionConsole = () => {
  let consoleHolder = console;
  let logger = {};
  Object.keys(consoleHolder).forEach(function (key) {
    logger[key] = function () {};
  });
  return logger;
};
const Logger =
  process.env.REACT_APP_DEBUG === "true" ? console : getProductionConsole();

export default Logger;
