import {
  Card,
  ResourceList,
  ResourceItem,
  Avatar,
  TextStyle,
  Page,
  Stack,
  Button,
  Layout,
  Badge,
} from "@shopify/polaris";
import React from "react";
import { Provider, TitleBar } from "@shopify/app-bridge-react";
import campaign_default_logo from "../assets/images/campaign.png";
import PluginApiClient from "../services/api/api-service";
import UiUtils from "../utils/ui-utils";
import Logger from "../utils/logger";
import { withRouter } from "react-router-dom";
import { store } from "../index";
import * as config from "../config";

class CampaignList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignList: [],
    };
    this.isLoaded = false;
    this.apiClient = new PluginApiClient();
  }

  componentDidMount() {
    let campaignList = this.props?.location?.state?.campaignList;
    Logger.log("componentDidMount campaignList", campaignList, this.props);
    if (campaignList || campaignList !== undefined) {
      this.isLoaded = true;
      this.setState({
        campaignList: campaignList,
      });
    } else {
      this.apiClient
        .fetchCampaigns()
        .then(
          (response) => {
            this.isLoaded = true;
            this.setState({
              campaignList: response.data.campaigns,
              //campaignList: MockResponse.CAMPAIGN_LIST
            });
          },
          (error) => {
            this.isLoaded = false;

            // UiUtils.showToast("error", `Request failed: ${error}`);
          }
        )
        .catch((error) => {
          // this.isLoaded = true;
          // this.setState({
          //   //campaignList: response.data.campaigns
          //   campaignList: MockResponse.CAMPAIGN_LIST,
          UiUtils.showToast("error", `Request failed: ${error}`);
        });
    }
  }
  handleAddCampaign = () => {
    this.props.history.push({ pathname: "/campaigns/create" });
  };

  render() {
    return (
      <>
        <TitleBar primaryAction={null} />
        <Page>
          <Card>
            <ResourceList
              resourceName={{ singular: "campaign", plural: "campaigns" }}
              items={this.state.campaignList}
              renderItem={(item) => {
                const {
                  id,
                  slug,
                  title,
                  start_date,
                  end_date,
                  Quantity,
                  campaign_progress,
                } = item;
                let campaign_status;
                let now = new Date();
                let _end_date = new Date(end_date);

                if (now > _end_date) {
                  campaign_status = ["Completed", "new"];
                } else {
                  campaign_status = ["Scheduled", "info"];
                }
                if (new Date(start_date) < now && now < _end_date) {
                  campaign_status = ["Active", "success"];
                }
                const media = (
                  <Avatar
                    customer
                    size="medium"
                    name={title}
                    source={campaign_default_logo}
                  />
                );

                return (
                  <ResourceItem
                    id={id}
                    onClick={() => {
                      this.props.history.push(`/campaigns/${slug}`);
                    }}
                    media={media}
                    accessibilityLabel={`View details for ${title}`}
                  >
                    <Layout>
                      <Layout.Section oneThird>
                        <h3>
                          <TextStyle variation="strong">{title}</TextStyle>
                        </h3>
                        <h4>
                          <TextStyle>{slug}</TextStyle>
                        </h4>
                      </Layout.Section>
                      <Layout.Section oneThird>
                        <Stack alignment="leading" wrap={false} vertical={true}>
                          <Badge status="default">
                            Starts on:{start_date} UTC
                          </Badge>
                          <Badge status="deafult" className="d-block">
                            Ends on: {end_date} UTC
                          </Badge>
                        </Stack>
                      </Layout.Section>
                      <Layout.Section oneThird>
                        <Stack
                          alignment="leading"
                          // spacing="tight"
                          wrap={false}
                          vertical={false}
                          distribution="fill"
                          spacing="tight"
                        >
                          {/* <Badge status='info'>{campaign_progress.progress} Completed</Badge> */}
                          <Badge status="success">
                            {campaign_progress.total_coupons} Coupons
                          </Badge>
                          <Badge status={campaign_status[1]}>
                            {campaign_status[0]}
                          </Badge>
                        </Stack>
                      </Layout.Section>
                    </Layout>
                  </ResourceItem>
                );
              }}
            />
          </Card>
          <Page>
            <Stack>
              <Button primary onClick={this.handleAddCampaign}>
                + Add campaign
              </Button>
            </Stack>
          </Page>
        </Page>
      </>
    );
  }
}

export default withRouter(CampaignList);
