import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import OfferxpApiClient from '../services/api/api-service';
//import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
//import '../../node_modules/react-bootstrap/dist/bootstrap.min.css';

import '../App.css';
import CookieService from "../services/cookie-service";
import Logger from "../utils/logger";


export default function Login(props) {
  let path  = props.match.path;
  let loginPage = path === '/login';
  let signUpPage = path === '/signup';
  document.body.classList.add('login-page')
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return userName.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const apiClient =new  OfferxpApiClient();
      apiClient.userLogin(userName, password).then(response => {
      localStorage.setItem('token', response.data.token);
      CookieService.setToken(response.data.token);
      window.location = '/';
    }).catch(error => {
      Logger.log(error.message)
    })
  }

  function renderPage() {
    let html;
    if (loginPage){
      html = RenderLogin();
    }
    if (signUpPage){
      html = RenderSignup();
    }
    return html;
  }

  function RenderLogin(){
    return (
      <Form onSubmit={handleSubmit}>
      <h3>Sign In</h3>
  
      <div className="form-group">
          <label>Username</label>
          <input type="text" className="form-control" placeholder="Enter email" 
          onChange={(e) => setUserName(e.target.value)}/>
      </div>
  
      <div className="form-group">
          <label>Password</label>
          <input type="password" className="form-control" placeholder="Enter password" 
          onChange={(e) => setPassword(e.target.value)} />
      </div>
  
      <div className="form-group">
          <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="customCheck1" />
              <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
          </div>
      </div>
  
      <button type="submit" className="btn btn-primary btn-block">Submit</button>
      <p className="forgot-password text-right">
          Forgot <a href="#">password?</a>
      </p>
  </Form>
    )
  }

  function RenderSignup(){
    return (
      <form>
      <h3>Sign Up</h3>
  
      <div className="form-group">
          <label>First name</label>
          <input type="text" className="form-control" placeholder="First name" />
      </div>
  
      <div className="form-group">
          <label>Last name</label>
          <input type="text" className="form-control" placeholder="Last name" />
      </div>
  
      <div className="form-group">
          <label>Email address</label>
          <input type="email" className="form-control" placeholder="Enter email" />
      </div>
  
      <div className="form-group">
          <label>Password</label>
          <input type="password" className="form-control" placeholder="Enter password" />
      </div>
  
      <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
      <p className="forgot-password text-right">
          Already registered <a href="/login">sign in?</a>
      </p>
      </form>
    )
  }

  // return (
  //   <div className="Login">
  //     <Form onSubmit={handleSubmit}>
  //       <Form.Group size="lg" controlId="userName">
  //         <Form.Label>UserName</Form.Label>
  //         <Form.Control
  //           autoFocus
  //           type="text"
  //           value={userName}
  //           onChange={(e) => setUserName(e.target.value)}
  //         />
  //       </Form.Group>
  //       <Form.Group size="lg" controlId="password">
  //         <Form.Label>Password</Form.Label>
  //         <Form.Control
  //           type="password"
  //           value={password}
  //           onChange={(e) => setPassword(e.target.value)}
  //         />
  //       </Form.Group>
  //       <Button block size="lg" type="submit" disabled={!validateForm()}>
  //         Login
  //       </Button>
  //     </Form>
  //   </div>
  // );

  return (
    renderPage()
  )
}