import * as axios from "axios";
import { KEY_SHOPIFY_TOKEN } from "../../config";
import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";

import CookieService from "../cookie-service";
import * as config from "../../config";
import Logger from "../../utils/logger";
import { store } from "../../index";
import { useAppBridge } from "@shopify/app-bridge-react";

const axiosApiInstance = axios.create();

function createShopifyApp() {
  Logger.log("shopifyHost", store.getState());
  return store.getState().data_states.shopifyAppBridgeInstance;
  return createApp({
    apiKey: config.SHOPIFY_APP_API_KEY,
    //host: localStorage.getItem("host"),
    host: store.getState().data_states.shopifyHost,
    // forceRedirect: true
  });
}

function getShopifySessionToken() {
  return getSessionToken(createShopifyApp());
}

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
    };

    let bearerToken = await getShopifySessionToken();
    // Logger.log("REQ Interceptor", bearerToken);
    config.headers["Shopify-Authorization"] = `Bearer ${bearerToken}`;
    return config;
  },
  (error) => {
    Logger.log("Axios req interceptor error", error);
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    response.headers["Content-Security-Policy"] =
      "frame-ancestors https://shopify-dev.myshopify.com https://admin.shopify.com;";
    return response;
  },
  async function (error) {
    Logger.log("RES Interceptor", error);
    if (error.response.status === 401) {
      CookieService.deleteToken();
    }
    return Promise.reject(error);
  }
);

export default class PluginApiClient {
  constructor() {
    this.api_token = null;
    this.client = axiosApiInstance;
    this.api_url = config.BASE_URL;
    this.shopify_url = this.api_url + "codegen/";
  }

  getClient = () => this.client;

  // init = () => {
  //   this.api_token = CookieService.getToken();

  //   // let headers = {
  //   //   Accept: "application/json",
  //   // };
  //      this.client=axiosApiInstance;
  //   // this.client = axios.create({
  //   //   baseURL: this.api_url,
  //   //   timeout: 31000,
  //   //   headers: headers,
  //   //   validateStatus: function (status) {
  //   //     return status === 200;
  //   //   },
  //   // });

  //   return this.client;
  // };

  fetchCampaigns() {
    return axiosApiInstance.get(this.shopify_url + "campaigns/");
  }

  fetchCampaign(campaign_id) {
    return axiosApiInstance.get(
      this.shopify_url + "campaigns/" + campaign_id + "/"
    );
  }

  addCampaign(data) {
    return axiosApiInstance.post(this.shopify_url + "campaigns/", data);
  }

  editCampaign(campaign_id, data) {
    return axiosApiInstance.put(
      `${this.shopify_url}campaigns/${campaign_id}/`,
      data
    );
  }

  deleteCampaign(campaign_id) {
    return axiosApiInstance.delete(
      `${this.shopify_url}campaigns/${campaign_id}/`
    );
  }

  fetchCouponCode(reference_number) {
    return axiosApiInstance.get(
      this.shopify_url + `coupons/${reference_number}/`
    );
  }

  fetchCouponCodes(campaign_slug) {
    return axiosApiInstance.get(
      `${this.shopify_url}campaigns/${campaign_slug}/coupons/`
    );
  }

  updateCouponCodes(coupon_code_id, data) {
    return axiosApiInstance.put(
      this.shopify_url + `coupons/${coupon_code_id}/`,
      data
    );
  }

  deleteCouponCodes(reference_numbers) {
    return axiosApiInstance.delete(this.shopify_url + "coupons/", {
      data: reference_numbers,
    });
  }

  downloadCouponCodes(campaign_id) {
    return axiosApiInstance.get(
      `${this.shopify_url}coupons/${this.shopify_url}/download/`
    );
  }

  exportShopifyCoupons(slug, mode) {
    return axiosApiInstance.get(
      `${this.shopify_url}campaigns/${slug}/export/?mode=${mode}`
      // {responseType: 'blob'}
    );
  }

  fetchStoreData = () => {
    return axiosApiInstance
      .get(this.shopify_url + "store_settings/", {})
      .then((response) => response)
      .catch((err) => err);
  };

  userLogin(username, password) {
    // return this.init().post(this.api_url + 'rest-auth/login/', {'username': username, 'password': password}, {})
    return axiosApiInstance.post(
      this.api_url + "api/token/",
      { username: username, password: password },
      {}
    );
  }

  userLogout() {
    return axiosApiInstance.post(this.api_url + "api/logout/");
  }

  initiateStoreConnection(storeURL) {
    // axiosApiInstance raises this error:
    // "Failed to execute 'postMessage' on 'DOMWindow': The target origin provided ('<URL>') does not match the recipient window's origin ('<URL>')"
    return axios.post(this.shopify_url + "connect_store/", {
      store_url: storeURL,
    });
  }
  saveStoreConnection(authData) {
    let queryParams = new URLSearchParams(authData).toString();
    return axiosApiInstance.post(
      this.shopify_url + `auth/callback/?${queryParams}`,
      {
        ...authData,
      }
    );
  }

  verifyEmbeddedApp(authData) {
    return axiosApiInstance.post(this.shopify_url + "auth/callback/", {
      ...authData,
    });
  }

  disconnectStore() {
    return axiosApiInstance.post(this.shopify_url + "disconnect_store/");
  }

  userIsLoggedIn() {
    return axiosApiInstance.get(this.shopify_url + "is_logged_in/", {});
  }
}
