import Campaign from "./pages/Campaign";
import CampaignList from "./pages/CampaignList";
import CouponCode from "./pages/CouponCode";
import Home from "./pages/Home";
import Login from "./pages/loginPage";
import StoreConnection from "./pages/StoreConnection";
import shopifyAuthCallback from "./pages/authCallback";
import CouponCodes from "./pages/CouponCodes";

// let loginPage = path === '/login';
// let signUpPage = path === '/signup';

const routes = [
  {
    path: "/home",
    exact: true,
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    exact: true,
    name: "Home",
    component: Login,
  },
  {
    path: "/signup",
    exact: true,
    name: "Home",
    component: Login,
  },
  {
    path: "/connect",
    exact: true,
    name: "Home",
    component: StoreConnection,
  },
  {
    path: "/auth/callback/",
    exact: true,
    name: "AuthCallback",
    component: shopifyAuthCallback,
  },
  {
    path: "/campaigns",
    exact: true,
    name: "Shopify campaigns",
    component: CampaignList,
  },
  {
    path: "/campaigns/create",
    exact: true,
    name: "Campaign Add",
    component: Campaign,
  },
  {
    path: "/campaigns/:slug/coupon-codes",
    exact: true,
    name: "Coupon Codes",
    component: CouponCodes,
  },
  {
    path: "/campaigns/:slug",
    exact: true,
    name: "Campaign Edit",
    component: Campaign,
  },
  {
    path: "/coupons/:reference_number",
    exact: true,
    name: "Coupon Code Detail",
    component: CouponCode,
  },
];

export default routes;
