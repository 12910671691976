// import swal from '@sweetalert/with-react';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SweetAlert = withReactContent(Swal);
const Toast = Swal.mixin({
  toast: true,
  position: "bottom-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

// UI - utilities for OfferXP
// Ref...
//https://sweetalert.js.org/guides/

const UiUtils = {
  showSimpleAlert: (msg) => {
    SweetAlert.fire(msg);
  },
  showSimplePopup: (title, msg) => {
    return SweetAlert.fire(title, msg);
  },
  showSuccessPopup: (title, msg) => {
    return SweetAlert.fire(title, msg, "success");
  },
  showErrorPopup: (title, msg) => {
    return SweetAlert.fire(title, msg, "error");
  },
  showInfoPopup: (title, msg) => {
    return SweetAlert.fire(title, msg, "info");
  },
  showWarningPopup: (title, msg) => {
    return SweetAlert.fire(title, msg, "warning");
  },
  showCustomAlert: (data) => {
    return SweetAlert.fire(data);
  },
  showToast: (type, title) => {
    return Toast.fire({ icon: type, title: title });
  },
  showConfirmDialog: (title, text, confirmButtonText) => {
    return SweetAlert.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmButtonText,
    });
  },
};

export default UiUtils;
