import Logger from "../../utils/logger";
import * as actionTypes from "../actions";

const initialState = {
  shopifyHost: "",
  setShopifyAppBridgeInstance: null,
};

const dataStatesReducer = (state = initialState, action) => {
  // Logger.log("DISPATCH", action);
  switch (action.type) {
    case actionTypes.SET_SHOPIFY_HOST:
      // Logger.log("DISPATCH1", action.payload);
      return {
        ...state,
        shopifyHost: action.payload,
      };
    case actionTypes.SET_SHOPIFY_APP:
      return {
        ...state,
        shopifyAppBridgeInstance: action.payload,
      };

    default:
      return state;
  }
};

export default dataStatesReducer;
