import { Button, Card, DataTable, EmptyState, Layout, Page, Stack } from "@shopify/polaris";
import { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import PluginApiClient from "../services/api/api-service";
import Logger from "../utils/logger";
import UiUtils from "../utils/ui-utils";
import {store} from "../index";
import * as config from "../config";
import { Provider, ResourcePicker, TitleBar } from "@shopify/app-bridge-react";


function CouponCodes(props) {

  const history = useHistory();
  const [couponVisiblity, setCouponVisiblity] = useState(false);
  const [couponsList, setCouponsList] = useState([]);

  const handleAction = ()=>{
    setCouponVisiblity(true);
  }

  useEffect(() => {    
     const apiClient = new PluginApiClient();
     apiClient
      .fetchCouponCodes(props.match.params.slug)
      .then(
        (response) => {
          rows = response.data.coupons;
          setCouponsList(response.data.coupons)
        },
        (error) => {
          UiUtils.showToast("error", `Request failed: ${error}`);
        }
      )
      .catch((error) => {
        UiUtils.showToast("error", `Request failed: ${error}`);
      });
     }, []);

    // let rows = couponsList.map(function (coupon) { return [coupon.code, coupon.status, coupon.reference_number] })
    let rows = couponsList.map(function (coupon) { return [coupon.code, coupon.reference_number] })

  return (
    couponsList.length > 0?
    <Provider config={{ apiKey: config.SHOPIFY_APP_API_KEY, host: store.getState().data_states.shopifyHost }}>
    <Page title="Coupon Codes">
      <TitleBar
       primaryAction={{
      content: '←  Go Back',
      // onAction: () => {props.history.push({pathname: props.location?.state?.prevPath})},
      onAction: () => {Logger.log("HISTORY",props.history);props.history.goBack()},
    }}/>
      <Card>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text"]}
          headings={["Code", "Reference Number"]}
          rows={rows}
        />
      </Card>
    </Page>
    </Provider>
    :
     null
  );
}

export default withRouter(CouponCodes);