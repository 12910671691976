// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
// import GA4React from "ga-4-react";

ReactGA.initialize("G-GTYQSF377D");

const EVENTS = {
	CODEGEN_CAMPAIGN_DISABLED_SUCCESS: "codegen-campaign-disabled-success",
	CODEGEN_CAMPAIGN_DISABLED_FAILED: "codegen-campaign-disabled-failed",
	CODEGEN_CAMPAIGN_DISABLED_ERROR: "codegen-campaign-disabled-error",
	CODEGEN_CAMPAIGN_ENABLED_SUCCESS: "codegen-campaign-enabled-success",
	CODEGEN_CAMPAIGN_ENABLED_FAILED: "codegen-campaign-enabled-failed",
	CODEGEN_CAMPAIGN_ENABLED_ERROR: "codegen-campaign-enabled-error",
	CODEGEN_CAMPAIGN_DELETED_SUCCESS: "codegen-campaign-deleted-success",
	CODEGEN_CAMPAIGN_DELETED_FAILED: "codegen-campaign-deleted-failed",
	CODEGEN_CAMPAIGN_DELETED_ERROR: "codegen-campaign-deleted-error",
	CODEGEN_CAMPAIGN_CREATED_SUCCESS: "codegen-campaign-created-success",
	CODEGEN_CAMPAIGN_CREATED_FAILED: "codegen-campaign-created-failed",
	CODEGEN_CAMPAIGN_CREATED_ERROR: "codegen-campaign-created-error",
	CODEGEN_CAMPAIGN_EDITED_SUCCESS: "codegen-campaign-edited-success",
	CODEGEN_CAMPAIGN_EDITED_FAILED: "codegen-campaign-edited-failed",
	CODEGEN_CAMPAIGN_EDITED_ERROR: "codegen-campaign-edited-error",
	CODEGEN_CAMPAIGN_COUPON_EXPORT_SUCCESS: "codegen-campaign-coupon-export-success",
	CODEGEN_CAMPAIGN_COUPON_EXPORT_FAILED: "codegen-campaign-coupon-export-failed",
	CODEGEN_CAMPAIGN_COUPON_EXPORT_ERROR: "codegen-campaign-coupon-export-error",
}

const publishEvent = (action, label) => {

	ReactGA.event(action, {
		// event_category: "shopping",
		event_label: label,
	});

	// ReactGA.event({
	//   category: "your category",
	//   action: "your action",
	//   label: "your label", // optional
	//   value: 99, // optional, must be a number
	//   nonInteraction: true, // optional, true/false
	//   transport: "xhr", // optional, beacon/xhr/image
	// });
 };

export {publishEvent, EVENTS};
